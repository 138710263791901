<template>
    <div class="print--title">
        <span :style="`font-size:${fontSize}px; font-weight:${fontWeight};`">
            {{ text }}</span
        >
        <span
            :style="`font-size:${fontSize}px; font-weight:${fontWeight};`"
            v-if="subtext"
            >{{ subtext }}</span
        >
        <span v-if="warranty">{{ warranty }}</span>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            required: true,
        },
        subtext: {
            type: String,
        },
        warranty: {
            type: String,
        },
        fontSize: {
            type: Number,
            default: 14,
        },
        fontWeight: {
            type: Number,
            default: 500,
        },
    },
}
</script>
<style lang="sass" scoped>
.print--title
    background-image: linear-gradient(90deg,#c3c3c3,hsla(0,0%,100%,0)) !important
    display: flex
    height: 0.7cm
    width: 100%
    min-width: 200px !important
    span
        margin: 2px 5px
        white-space: nowrap
</style>
