<template>
    <v-app>
        <v-container id="spinner">
            <div id="printPage" class="page" size="A4">
                <div class="header">
                    <div class="header__logo">
                        <img
                            v-if="getSettingsDetails.logo"
                            :src="`${path}/client_logo.png`"
                            alt="entelli logo"
                        />
                    </div>
                    <div class="header__text">
                        <span>{{ getSettingsDetails.companyName }}</span>
                        <span
                            >{{ `${$t('service:str')} `
                            }}{{ getSettingsDetails.companyAddress }},
                            {{ getSettingsDetails.city }}</span
                        >
                        <span
                            >{{ $t('service:tel') }}
                            {{ getSettingsDetails.companyPhone }}</span
                        >
                        <span>{{ getSettingsDetails.companyEmail }}</span>
                        <span>{{ getSettingsDetails.website }}</span>
                    </div>
                </div>
                <div class="central">
                    <div class="central__left">
                        <Title
                            :text="$t('service:client')"
                            :subtext="`${$get(
                                getServiceDetails,
                                'client.contactId',
                                ''
                            )}`"
                        />
                        <span>{{
                            `${$get(
                                getServiceDetails,
                                'device.client.name',
                                ''
                            )}`
                        }}</span>
                        <span
                            >{{ $t('service:str')
                            }}{{
                                `${$get(
                                    getServiceDetails,
                                    'device.client.street',
                                    ''
                                )}`
                            }}</span
                        >
                        <span class="d-flex">
                            {{
                                `${$get(
                                    getServiceDetails,
                                    'device.client.postCode',
                                    ''
                                )}`
                            }}
                            {{
                                `${$get(
                                    getServiceDetails,
                                    'device.client.city',
                                    ''
                                )}`
                            }}
                        </span>
                        <span
                            >{{ $t('service:tel')
                            }}{{
                                `${$get(
                                    getServiceDetails,
                                    'device.client.phoneNumber',
                                    ''
                                )}`
                            }}</span
                        >
                        <Title :text="$t('service:contractor')" class="mt-3" />
                        <span>{{
                            `${$get(
                                getServiceDetails,
                                'contractor.name',
                                ''
                            )} ${$get(
                                getServiceDetails,
                                'contractor.lastname',
                                ''
                            )}`
                        }}</span>
                        <span>
                            {{ $t('service:tel')
                            }}{{
                                `${$get(
                                    getServiceDetails,
                                    'contractor.phoneNumber',
                                    ''
                                )}`
                            }}</span
                        >
                        <span>
                            {{ $t('service:email') }}
                            {{
                                `${$get(
                                    getServiceDetails,
                                    'contractor.email',
                                    ''
                                )}`
                            }}</span
                        >
                        <Title :text="$t('service:serviceData')" class="mt-3" />
                        <span class="mt-3">{{
                            getServiceDetails.plannedServiceDate
                                ? $moment(
                                      getServiceDetails.plannedServiceDate
                                  ).format(`dddd, DD-MM-YYYY`)
                                : 'Brak'
                        }}</span>
                        <Title :text="$t('service:technician')" class="mt-3" />
                        <span class="mt-3">
                            {{
                                `${$get(
                                    getServiceDetails,
                                    'technician.name',
                                    ''
                                )} ${$get(
                                    getServiceDetails,
                                    'technician.lastname',
                                    ''
                                )} `
                            }}</span
                        >
                        <Title :text="$t('service:device')" class="mt-3" />
                        <span
                            >{{ `${$t('service:model')}:` }}
                            {{
                                `${$get(
                                    getServiceDetails,
                                    'device.model.model',
                                    ''
                                )}`
                            }}</span
                        >
                        <span
                            >{{ `${$t('models:manufacturer')}:` }}
                            {{
                                `${$get(
                                    getServiceDetails,
                                    'device.manufacturer',
                                    ''
                                )}`
                            }}</span
                        >
                        <span
                            >{{ `${$t('service:sn')}:` }}
                            {{
                                `${$get(getServiceDetails, 'device.sn', '')}`
                            }}</span
                        >
                        <span
                            >{{ `${$t('service:serviceBasic')}:` }}
                            {{
                                `${$get(
                                    getServiceDetails,
                                    'device.serviceBase.field',
                                    ''
                                )}`
                            }}</span
                        >
                        <span
                            >{{ `${$t('service:warrantyTo')}` }}
                            {{
                                getServiceDetails.device.guaranteeTo
                                    ? $moment(
                                          getServiceDetails.device.guaranteeTo
                                      ).format(`DD-MM-YYYY`)
                                    : ''
                            }}</span
                        >
                        <span
                            >{{ `${$t('service:location')}:` }}
                            {{ `${$get(location, 'address', '')}` }},
                            {{ `${$get(location, 'postCode', '')}` }}
                            {{ `${$get(location, 'city', '')}` }}</span
                        >
                        <span
                            >{{ `${$t('service:floor')}:` }}
                            {{
                                `${$get(getServiceDetails, 'device.floor', '')}`
                            }}</span
                        >
                        <Title
                            :text="$t('service:deviceCounter')"
                            class="mt-3"
                        />
                        <span
                            >{{ $t('service:countMonoStart') }}
                            {{
                                `${$get(
                                    getServiceDetails,
                                    'realization.counters.monoDeviceStart',
                                    ''
                                )}`
                            }}</span
                        >
                        <span
                            >{{ $t('service:countMonoEnd') }}
                            {{
                                `${$get(
                                    getServiceDetails,
                                    'realization.counters.monoDeviceEnd',
                                    ''
                                )}`
                            }}</span
                        >
                        <span
                            >{{ `${$t('service:countColorStart')}` }}
                            {{
                                `${$get(
                                    getServiceDetails,
                                    'realization.counters.colorDeviceStart',
                                    ''
                                )}`
                            }}</span
                        >
                        <span
                            >{{ $t('service:countColorEnd') }}
                            {{
                                `${$get(
                                    getServiceDetails,
                                    'realization.counters.colorDeviceEnd',
                                    ''
                                )}`
                            }}</span
                        >
                    </div>
                    <div class="central__right">
                        <Title
                            :fontSize="14"
                            :text="`${$t(
                                'service:interventionProtocol'
                            )} ${$get(getServiceDetails, 'index', '')}`"
                        />
                        <Title
                            class="mt-3"
                            :text="`${$t('service:scopeOfTheService')} ${$get(
                                getServiceDetails,
                                'device.model.servicePrice.name',
                                ''
                            )}`"
                        />
                        <table border="1" class="print--table">
                            <tr>
                                <th width="150">{{ $t('service:survey') }}</th>
                                <th width="80">{{ $t('service:repair') }}</th>
                                <th width="150">
                                    {{
                                        $t('service:implementationInstalation')
                                    }}
                                </th>
                                <th width="80">
                                    {{ $t('service:diagnostics') }}
                                </th>
                                <th width="80">
                                    {{ $t('service:ItService') }}
                                </th>
                            </tr>
                            <tr class="col-center">
                                <td>
                                    {{
                                        `${$get(
                                            getServiceDetails,
                                            'realization.survey',
                                            ''
                                        )}`
                                    }}
                                    h
                                </td>
                                <td>
                                    {{
                                        `${$get(
                                            getServiceDetails,
                                            'realization.repair',
                                            ''
                                        )}`
                                    }}
                                    h
                                </td>
                                <td>
                                    {{
                                        `${$get(
                                            getServiceDetails,
                                            'realization.installation',
                                            ''
                                        )}`
                                    }}
                                    h
                                </td>
                                <td>
                                    {{
                                        `${$get(
                                            getServiceDetails,
                                            'realization.diagnostics',
                                            ''
                                        )}`
                                    }}
                                    h
                                </td>
                                <td>
                                    {{
                                        `${$get(
                                            getServiceDetails,
                                            'realization.itService',
                                            ''
                                        )}`
                                    }}
                                    h
                                </td>
                            </tr>
                        </table>
                        <Title
                            class="mt-3"
                            :fontSize="14"
                            :text="$t('service:customerDescription')"
                        />
                        <table border="1" class="print--table">
                            <tr>
                                <td
                                    width="540"
                                    valign="top"
                                    class="px-1 description--height"
                                >
                                    {{
                                        `${$get(
                                            getServiceDetails,
                                            'clientDescription',
                                            ''
                                        )}`
                                    }}
                                </td>
                            </tr>
                        </table>
                        <Title
                            v-if="forTechnic"
                            class="mt-3"
                            :fontSize="14"
                            :text="$t('service:logisticDescription')"
                        />
                        <table
                            v-if="forTechnic"
                            border="1"
                            class="print--table description--height"
                        >
                            <tr>
                                <td width="540" valign="top" class="px-1">
                                    {{
                                        `${$get(
                                            getServiceDetails,
                                            'logisticianDescription',
                                            ''
                                        )}`
                                    }}
                                </td>
                            </tr>
                        </table>
                        <Title
                            v-if="forTechnic"
                            class="mt-3"
                            :fontSize="14"
                            :text="$t('service:lastInterventionInformation')"
                        />

                        <table
                            v-if="forTechnic"
                            border="1"
                            class="print--table"
                        >
                            <tr>
                                <td width="90" class="px-1">
                                    {{ $t('service:countMono') }}
                                </td>
                                <td width="180" class="px-1">
                                    {{
                                        `${$get(
                                            getPrevIntervention,
                                            'realization.counters.monoDeviceEnd',
                                            ''
                                        )}`
                                    }}
                                </td>
                                <td width="90" class="px-1">
                                    {{ $t('service:technic') }}
                                </td>
                                <td width="180" class="px-1">
                                    {{
                                        `${$get(
                                            getPrevIntervention,
                                            'technician.name',
                                            ''
                                        )}`
                                    }}
                                    {{
                                        `${$get(
                                            getPrevIntervention,
                                            'technician.lastname',
                                            ''
                                        )}`
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td width="90" class="px-1">
                                    {{ $t('service:countColor') }}
                                </td>
                                <td width="180" class="px-1">
                                    {{
                                        `${$get(
                                            getPrevIntervention,
                                            'realization.counters.colorDeviceEnd',
                                            ''
                                        )}`
                                    }}
                                </td>
                                <td width="90" class="px-1">
                                    {{ $t('service:date') }}
                                </td>
                                <td width="180" class="px-1">
                                    {{
                                        getPrevIntervention.realization
                                            ? $moment(
                                                  getPrevIntervention
                                                      .realization.startDate
                                              ).format(`DD-MM-YYYY`)
                                            : 'Brak'
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td width="90" class="px-1">
                                    {{ $t('service:deduceLastIntervention') }}
                                </td>
                                <td width="180" class="px-1">
                                    {{
                                        `${$get(
                                            getPrevIntervention,
                                            'realization.technicianComments',
                                            ''
                                        )}`
                                    }}
                                </td>
                                <td width="90" class="px-1">
                                    {{ $t('service:scopeLastIntervention') }}
                                </td>
                                <td width="180" class="px-1">
                                    {{
                                        `${$get(
                                            getPrevIntervention,
                                            'realization.interventionRange',
                                            ''
                                        )}`
                                    }}
                                </td>
                            </tr>
                        </table>
                        <Title
                            class="mt-3"
                            :fontSize="14"
                            :text="$t('service:serviceDescription')"
                        />
                        <table border="1" class="print--table">
                            <tr>
                                <td
                                    valign="top"
                                    width="540"
                                    class="px-1 intervention--height"
                                >
                                    {{
                                        `${$get(
                                            getServiceDetails,
                                            'realization.interventionRange',
                                            ''
                                        )}`
                                    }}
                                </td>
                            </tr>
                        </table>
                        <table
                            v-if="isPrice"
                            border="1"
                            class="print--table cost--table mt-3"
                        >
                            <tr>
                                <th width="25">{{ $t('service:number') }}</th>
                                <th width="335">
                                    {{ $t('service:name') }}
                                </th>
                                <th width="45">
                                    {{ $t('service:nettoPrice') }}
                                </th>
                                <th width="45">{{ $t('service:VAT') }}</th>
                                <th width="45">
                                    {{ $t('service:VATValue') }}
                                </th>
                                <th width="45">
                                    {{ $t('service:bruttoValue') }}
                                </th>
                            </tr>
                            <tr class="col-center">
                                <td>1</td>
                                <td class="text--left px-1">
                                    {{ $t('service:parts.default') }}
                                </td>
                                <td>
                                    <money
                                        v-bind="money"
                                        class="table--font"
                                        readonly
                                        :value="
                                            getServiceDetails.parts
                                                ? setTotalWasUsedSum(
                                                      getServiceDetails.parts
                                                          .partsTable
                                                  ).toFixed(2)
                                                : 0
                                        "
                                    ></money>
                                </td>
                                <td>{{ vat }}</td>
                                <td>
                                    <money
                                        v-bind="money"
                                        class="table--font"
                                        readonly
                                        :value="
                                            getServiceDetails.parts
                                                ? vatValue(
                                                      this.setTotalWasUsedSum(
                                                          this.getServiceDetails
                                                              .parts.partsTable
                                                      )
                                                  )
                                                : 0
                                        "
                                    ></money>
                                </td>
                                <td>
                                    <money
                                        v-bind="money"
                                        class="table--font"
                                        readonly
                                        :value="
                                            getServiceDetails.parts
                                                ? (
                                                      setTotalWasUsedSum(
                                                          getServiceDetails
                                                              .parts.partsTable
                                                      ) +
                                                      0.23 *
                                                          setTotalWasUsedSum(
                                                              getServiceDetails
                                                                  .parts
                                                                  .partsTable
                                                          )
                                                  ).toFixed(2)
                                                : 0
                                        "
                                    ></money>
                                </td>
                            </tr>

                            <tr class="col-center">
                                <td>2</td>
                                <td class="text--left px-1">
                                    {{ $t('service:ITService') }}
                                </td>
                                <td>
                                    <money
                                        v-bind="money"
                                        class="table--font"
                                        readonly
                                        :value="getPrice('sum')"
                                    ></money>
                                </td>
                                <td>{{ vat }}</td>
                                <td>
                                    <money
                                        v-bind="money"
                                        class="table--font"
                                        readonly
                                        :value="
                                            this.getServiceDetails.parts
                                                ? vatValue(getPrice('sum'))
                                                : 0
                                        "
                                    ></money>
                                </td>
                                <td>
                                    <money
                                        v-bind="money"
                                        class="table--font"
                                        readonly
                                        :value="
                                            getPrice('sum') +
                                            0.23 * getPrice('sum')
                                        "
                                    ></money>
                                </td>
                            </tr>
                            <tr class="col-center">
                                <td>3</td>
                                <td class="text--left px-1">
                                    {{ $t('service:transport') }}
                                </td>
                                <td>
                                    <money
                                        v-bind="money"
                                        class="table--font"
                                        readonly
                                        :value="carNetto()"
                                    ></money>
                                </td>
                                <td>{{ vat }}</td>
                                <td>
                                    <money
                                        v-bind="money"
                                        class="table--font"
                                        readonly
                                        :value="
                                            getServiceDetails.realization
                                                .counters
                                                ? vatValue(carNetto())
                                                : 0
                                        "
                                    ></money>
                                </td>
                                <td>
                                    <money
                                        v-bind="money"
                                        class="table--font"
                                        readonly
                                        :value="carNetto() + 0.23 * carNetto()"
                                    ></money>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div class="footer">
                    <div class="footer__signatures">
                        <div>
                            <p class="ma-0">
                                {{ $t('service:clientSignature') }}
                            </p>
                            <img
                                :src="`${$get(
                                    getServiceDetails,
                                    'realization.signature.signature.data',
                                    ''
                                )}`"
                            />
                        </div>
                        <div>
                            <p>{{ $t('service:technicSignature') }}</p>
                        </div>
                    </div>
                </div>

                <div id="printButtons" class="printOrder--buttons no-print">
                    <v-btn
                        @click="downloadDoc()"
                        fab
                        small
                        class="buttons--add no-print"
                        icon
                    >
                        <v-icon size="20">mdi-download</v-icon>
                    </v-btn>
                    <v-btn
                        @click="printDoc()"
                        fab
                        small
                        class="buttons--add mx-2 no-print"
                        icon
                    >
                        <v-icon size="20">mdi-printer</v-icon>
                    </v-btn>
                    <v-menu
                        v-model="emailMenu"
                        :close-on-content-click="false"
                        :nudge-width="260"
                        offset-x
                        :max-width="440"
                        ><template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                fab
                                small
                                class="buttons--add no-print"
                                icon
                            >
                                <v-icon size="20">mdi-email-fast</v-icon>
                            </v-btn></template
                        ><v-card class="pa-4" height="120">
                            <v-combobox
                                @input.native="
                                    representativeMail = $event.srcElement.value
                                "
                                @input="representativeMail = $event"
                                dense
                                return-object
                                item-value="_id"
                                :label="$t('service:emailAddress')"
                                :items="contractors"
                                ><template v-slot:selection="data">
                                    <div
                                        class="mr-1"
                                        v-if="
                                            !data.item.name &&
                                            !data.item.lastname
                                        "
                                    >
                                        {{ data.item }}
                                    </div>
                                    <div class="mr-1" v-else>
                                        {{
                                            ` ${data.item.name} ${data.item.lastname}`
                                        }}
                                    </div>
                                    <span class="mr-1" v-if="data.item.email">
                                        {{ ` • ${data.item.email} ` }}
                                    </span>
                                    <span v-if="data.item.phoneNumber">
                                        {{ ` • ${data.item.phoneNumber}` }}
                                    </span>
                                </template>
                                <template v-slot:item="data">
                                    <div class="mr-1">
                                        {{
                                            ` ${data.item.name} ${data.item.lastname}`
                                        }}
                                    </div>
                                    <span class="mr-1" v-if="data.item.email">
                                        {{ ` • ${data.item.email} ` }}
                                    </span>
                                    <span v-if="data.item.phoneNumber">
                                        {{ ` • ${data.item.phoneNumber}` }}
                                    </span></template
                                >
                                <template slot="prepend">
                                    <v-icon
                                        color="#19aa8d"
                                        class="mt-1"
                                        size="20"
                                        >mdi-email</v-icon
                                    >
                                </template>
                            </v-combobox>
                            <v-btn
                                @click="sendServicePrint()"
                                dark
                                class="buttons buttons--add no-print"
                                style="position: absolute; right: 30px;"
                                >{{ $t('global:send') }}</v-btn
                            >
                        </v-card></v-menu
                    >
                    <!-- <img :src="x" alt="entelli logo" /> -->
                </div>
            </div>
        </v-container>
        <!-- <Snackbars /> -->
    </v-app>
</template>
<script>
import Vue from 'vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Title from './Title'
import store from './../../../../store/index'
import jspdf from 'jspdf'
import domtoimage from 'dom-to-image'
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image'
export default {
    data() {
        return {
            path: `${process.env.VUE_APP_SERVER}/secured/logo/`,
            forTechnic: true,
            vat: 23,
            partsBrutto: 0,
            isPrice: false,
            x: '',
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: ' PLN',
                precision: 2,
                masked: false,
            },
            emailMenu: false,
            representativeMail: '',
        }
    },
    computed: {
        ...mapActions(['fetchSettings']),
        ...mapGetters([
            'getServiceDetails',
            'getSettingsDetails',
            'getPrevIntervention',
        ]),
        location: {
            get() {
                let location = this.getServiceDetails.device.client.branches.find(
                    el => el._id === this.getServiceDetails.device.branch
                )
                return location
            },
        },
        contractors: {
            get() {
                let representatives = this.$get(
                    this.getServiceDetails,
                    'device.client.representatives',
                    []
                )
                return representatives.filter(
                    representative => representative.email
                )
            },
        },
    },
    components: {
        Title,
    },
    methods: {
        ...mapActions(['fetchLastService']),
        ...mapMutations(['setSnackbars']),
        async sendServicePrint() {
            let mail
            if (typeof this.representativeMail === 'string')
                mail = this.representativeMail
            else mail = this.representativeMail.email
            let page = document.getElementById('printPage')
            let settings = { height: 1100, width: 800 }
            this.emailMenu = false
            document.getElementById('printButtons').style =
                'visibility: hidden;'
            try {
                let data = await toJpeg(page, settings)
                document.getElementById('printButtons').style =
                    'visibility: visible;'
                let img = new Image()
                img.src = data
                let doc = new jspdf('p', 'mm', 'a4')
                doc.addImage(img, 'JPEG', 0, 0, 200, 280)

                let form = new FormData()
                form.append('case', 'pdf')
                form.append('mail', `${mail}`)
                form.append('index', `${this.getServiceDetails.index}`)
                form.append('code', `${this.genCode(8)}`)
                form.append('doc', doc.output('blob'))
                document.getElementById(
                    'spinner'
                ).innerHTML = `<div class="preloader--text">
                    ${this.$t('service:emailSending')}
                    </div>
                    </div><div id="preloader">
                    <div id="loader"></div>`
                setTimeout(() => {
                    this.setSnackbars({
                        type: 'success',
                        text: `${this.$t('service:emailWasSend')}`,
                    })
                    setTimeout(() => {
                        location.reload()
                    }, 3000)
                }, 2000)
                let res = await new this.Rest('POST', '/service/sendOrder')
                    .setBody(form)
                    .send()
            } catch (error) {
                console.error(error)
            }
        },
        genCode(len) {
            const chars =
                '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
            let code = ''
            for (let i = 0; i < len; i++) {
                let index = Math.floor(Math.random() * chars.length)
                code += chars[index]
            }
            return code
        },

        async genDoc() {
            let mail
            if (typeof this.representativeMail === 'string')
                mail = this.representativeMail
            else mail = this.representativeMail.email
            let page = document.getElementById('printPage')
            let settings = { height: 1200, width: 800 }
            let scale = -1
            this.emailMenu = false
            document.getElementById('printButtons').style =
                'visibility: hidden;'
            try {
                let data = await toJpeg(page, settings)
                document.getElementById('printButtons').style =
                    'visibility: visible;'
                let doc = new jspdf()
                doc.addImage(data, scale, 0)
                return doc
            } catch (e) {
                console.error(e)
            }
        },

        printDoc() {
            window.print()
        },

        async downloadDoc() {
            let doc = await this.genDoc()
            doc.save()
        },

        checkType() {
            if (this.$route.query.isPrice == 'true') {
                this.isPrice = true
            }
            if (this.$route.query.isTechnic === 'technic')
                this.forTechnic = true
            else this.forTechnic = false
        },
        setTotalWasUsedSum(items) {
            const discount = this.$get(
                this.getServiceDetails,
                'device.model.servicePrice.partsDiscountInPercent.price',
                ''
            )

            if (!discount) {
                let temp = [0]
                items.forEach(item => {
                    const sum = item.price * item.quantity
                    if (item.wasUsed) temp.push(sum)
                })
                const totalSum = parseInt(temp.reduce((acc, val) => acc + val))
                return totalSum
            } else {
                let temp = [0]
                items.forEach(item => {
                    const sum = item.price * item.quantity
                    temp.push(sum)
                })
                let totalSum = parseInt(temp.reduce((acc, val) => acc + val))
                totalSum = totalSum - totalSum * (discount * 0.01)
                return totalSum
            }
        },
        vatValue(netto) {
            const brutto = netto + 0.23 * netto
            const sum = brutto - netto
            return sum.toFixed(2)
        },
        carNetto() {
            const cost = 1
            let distance = 0
            if (
                this.getServiceDetails.realization.counters &&
                this.getServiceDetails.realization.counters.carEnd &&
                this.getServiceDetails.realization.counters.carStart
            ) {
                distance =
                    this.getServiceDetails.realization.counters.carEnd -
                    this.getServiceDetails.realization.counters.carStart
            }
            return distance * cost
        },
        getPrice(type) {
            let sum
            let {
                repair,
                survey,
                itService,
                diagnostics,
                installation,
            } = this.getServiceDetails.device.model.servicePrice
            if (
                this.getServiceDetails.device.dedicatedPrice &&
                type !== 'sum'
            ) {
                sum =
                    this.getServiceDetails.device[type].dedicated *
                    this.getServiceDetails.realization[type]
            } else if (type !== 'sum')
                sum =
                    this.getServiceDetails.device.model.servicePrice[type]
                        .price * this.getServiceDetails.realization[type]
            if (type === 'sum') {
                sum =
                    Number(this.getPrice('repair')) +
                    Number(this.getPrice('survey')) +
                    Number(this.getPrice('itService')) +
                    Number(this.getPrice('diagnostics')) +
                    Number(this.getPrice('installation'))
                this.getServiceDetails.realization.standardPrice = sum
            }
            if (!Number.isNaN(sum)) return sum
            else return ''
        },
    },
    props: {
        type: {
            type: String,
        },
    },
    beforeMount() {
        this.checkType()
        store.dispatch('fetchSettings', {
            type: 'add',
        })
    },
}
</script>

<style lang="sass" scoped>
body
  background: rgb(204,204,204)
// ============ HEADER ============
table, th, td
    border-collapse: collapse
    height: 22px !important
.col-center>td
    text-align: center
.intervention--height
    height: 100px !important
.description--height
    height: 50px !important
    max-width: 540 !important
.print--table
    font-size: 11px
    font-weight: 300
.printOrder--buttons
    position: sticky
    bottom: 50px
    display: flex
    justify-content: flex-end
.text--left
    text-align: left !important
.header
  height: 2.5cm
  display: flex
  justify-content: space-between
  &__logo
    display: flex
    align-items: center
    width: 50%
    padding: 20px 10px
    max-width: 250px
    img
      width: auto
      height: 50px
  &__text
    display: flex
    flex-direction: column
    width: 50%
    margin-right: 15px
    font-size: 13px
    span
      text-align: right
// ============ CENTRAL ============
.central
  height: 800px
  display: flex
  margin-top: 0.7cm
  &__left
    height: 100%
    margin-right: 0.5cm
    width: 5.5cm
    span
      font-size: 12px
      display: block

      margin-left: 2px
  &__right
    width: 14.7cm
    height: 100%
// ============ FOOTER ============
.footer
  height: 5cm
  position: relative
  padding: 20px
  &__signatures
    width: 100%
    height: 1.6cm
    display: flex
    justify-content: flex-end
    div
      width: 25%
      height: 100%
      margin: 0 20px
      display: flex
      flex-direction: column
      justify-content: space-between
      position: relative
      border-bottom: 2px dotted black
      text-align: center
      img
          width: 80px
      span
          position: absolute
          bottom: -10px
      p
          white-space: nowrap
.page
  background: white
  padding: 14px
  display: block
  margin: 0 auto
  position: absolute
  top: 0
  left: 0
  margin-bottom: 0.5cm
  box-shadow: 0 0 0.5cm rgba(0,0,0,0.5)

// .page[size="A4"]
//   width: 2400px
//   height: 3508px
//   margin-left: 0 !important

.table--font
    font-size: 11px !important


.cost--table
    max-width: 540px
.cost--table tr td
    max-width: 90px !important
    padding: 0 3px !important
@media print
  .page
   box-shadow: 0 0 0cm rgba(0,0,0,0) !important

@media print
    .no-print, .no-print *
        display: none !important
</style>
