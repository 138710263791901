<template>
    <v-container>
        <PrintOrder></PrintOrder>
    </v-container>
</template>
<script>
import PrintOrder from './../../../components/Registries/Service/PrintOrder/PrintOrder'
import store from './../../../store/index'
export default {
    data() {
        return {}
    },
    method: {},
    components: {
        PrintOrder,
    },
    beforeRouteEnter(to, from, next) {
        store.dispatch('fetchService', { id: to.query.id, next })
    },
}
</script>
